import {
  exists,
  stickify,
  isCompactView
} from '../base/dom-utils.js';
import {
  $_PAGE_,
  $_FOOTER_,
  $_BOOKING_BAR_MAIN_,
  reservationDataFromAEM,
  _LOCALE_,
  _SEO_LOCALE_
} from '../base/vars.js';
import {
  getHeaderHeight,
  getNumDays,
  EventHandler,
  formatDateForUplift,
  formatDateForPrinting,
  formatTimeShow
} from '../base/utils.js';
import{
  getSessionStorage
}from '../base/session-handler.js';
import { ModifySession } from '../pages/modify.js';
import { BBForm } from './booking-bar.js';
import BookingSummary from './booking-summary.js';
import { setCriteria,getSessionCriteria } from '../base/session-handler.js';
import BNPL from './bnpl-uplift.js';
import { currencyConverter } from '../base/currency-converter.js';
import HideProvinceCountryConfig from '../base/aem-configs/hide-Province-Country';

class RateSummary {
  constructor() {
    if (exists('.res-rate-summary')) {
      this.bindMobileToggle();
    }
    if (reservationDataFromAEM && document.getElementsByClassName('confirmation-component').length) {
      BookingSummary.totalForStayConversion(
        Number(reservationDataFromAEM['totalCash'].replace('$', '').split(' ')[0]),
        reservationDataFromAEM['currencyCode']
      );
      currencyConverter.updateDisclaimerTime();
    }
    // By Default expanded
    if (exists('.new-rate-summary')) {
      $('.booking-sum__info .rate-aspects').addClass('open');
      $('.res-rate-info .collapse-button .toggle-mobile:not(:first-child)').show();
    }
  }

  bindMobileToggle() {
    $(document).on('click', '.res-rate-summary .collapse-button', (e) => {
      $('.booking-sum__info .rate-aspects').toggleClass('open');
      $(this).toggleClass('active');
      if ($_PAGE_.is('.packages-page')) {
        $(e.target).closest('.res-rate-summary').find('.toggle-mobile:not(".property-data, .room-info")').toggle();
      } else {
        $(e.target).closest('.res-rate-summary').find('.toggle-mobile').toggle();
      }
    });
  }

  
}

class RateSummaryBooking extends RateSummary {
  constructor(options) {
    super();
    if($_PAGE_.is('.booking-page')) {
      this.setBSWConfig.call(this);
    }
    this.$0 = $('.rate-summary-content'),
    this.$container = $('.res-rate-summary'),
    this.$parent = $('.res-rate-info'),
    this.$bookWithConfidence = $('.rs-book-with-conf'),
    this.stickyOptsBookWithConfidence = {},
    this.isResized = false,
    this.opts = options || {};

    $('.payment-radio-button').change(() => {
      if (!isCompactView()) {
        this.stickRateSummary();
        $(window).trigger('scroll');

        setTimeout(() => {
          this.stickRateSummary();
          $(window).trigger('scroll');
        }, 500);
      }
    });

    this.scrollCb = () => {
      this.makeRateSummarySticky();
    };

    if (!isCompactView()) {
      EventHandler.one(EventHandler.serviceCall.end, () => this.stickRateSummary());
    }

    $(window).on('resize',() => {
      $(window).off('scroll', this.scrollCb);

      if (!isCompactView()) {
        this.isResized = true;
        this.stickRateSummary();
        setTimeout(() => {
          this.isResizedBookwithConfidence();
          $(window).trigger('scroll');
        }, 100);
      }
    });
  }
  setConfigAddRemoveEle(newConfigFlag, oldConfig, formDiv, newConfig) {
    // Remove old/New widget based on the flag newConfigFlag
    if (formDiv && newConfig && oldConfig) {
      if (newConfigFlag) {
        const secondRateSummaryDivInNEw = document.querySelector(
          '#newWrapperBSW .new-rate-summary ~ .res-rate-summary.container-background'
        );
        // two divs are coming with class name "res-rate-summary" so remove the one which does not have class "new-rate-summary"
        secondRateSummaryDivInNEw && secondRateSummaryDivInNEw.remove();
        oldConfig.remove();
        formDiv.classList.add('display');
        newConfig.classList.add('display');
      } else {
        // two divs are coming with class name "res-rate-summary" so remove the one which does not have class "new-rate-summary"
        const secondRateSummaryDivInOld = document.querySelector('#oldWrapperBSW .new-rate-summary');
        secondRateSummaryDivInOld && secondRateSummaryDivInOld.remove();
        newConfig.remove();
        formDiv.classList.add('col-md-16', 'col-md-pull-8');
        formDiv.classList.add('display');
        oldConfig.classList.add('display');
      }
    }
  }
  isConfigExist() {
    return (
      window.wyndham &&
      window.wyndham.configs &&
      window.wyndham.configs['bsw-config'] &&
      window.wyndham.configs['bsw-config'].configs &&
      window.wyndham.configs['bsw-config'].configs[0]
    );
  }

  enableNewOrOldWidget() {
    let device = undefined;
    let locale = undefined;
    const devices = this.isConfigExist() && this.isConfigExist().channels;
    const locales = this.isConfigExist() && this.isConfigExist().locales;
    if (devices && locales) {
      if (window.innerWidth < 720) {
        device = devices.includes('mobileweb');
      } else if (window.innerWidth >= 720 && window.innerWidth <= 1199) {
        device = devices.includes('tablet');
      } else {
        device = devices.includes('desktop');
      }
      // check for the locale
      locale = locales.includes(_LOCALE_) || locales.includes(_SEO_LOCALE_);
      return device && locale;
    }
    return false;
  }

  setBSWConfig() {
    const configFlag = this.enableNewOrOldWidget.call(this);
    const oldConfig = document.getElementById('oldWrapperBSW');
    const formDiv = document.querySelector('.bsw-col-toggle');
    const newConfig = document.getElementById('newWrapperBSW');
    this.setConfigAddRemoveEle(configFlag, oldConfig, formDiv, newConfig);
  }
  stickRateSummary() {
    let top = getHeaderHeight();
    top += ($('#bookingBarHeight').height()) ? $_BOOKING_BAR_MAIN_.outerHeight() : 40;

    this.opts.start = this.$parent.height() - top;
    if (this.isBookingWithCondifenceDisplayed()) {
      this.opts.end = this.getFooterStickyPosition(top);
    } else {
      this.opts.endOnEl = $_FOOTER_;
    }
    if(this.isResized){
      this.$0.hasClass('stick') ? this.$0.removeClass('stick') : '';
    }

    this.opts.stick = {
      css: {
        zIndex: 5,
        top: top,
        left: this.$parent.offset().left,
        width: this.$container.width(),
        padding: this.$parent.css('padding'),
        // FF workaround
        paddingTop: this.$parent.css('paddingTop'),
        paddingBottom: this.$parent.css('paddingBottom'),
        paddingRight: this.$parent.css('paddingRight'),
        paddingLeft: this.$parent.css('paddingLeft'),
        // End
        background: this.$container.css('background'),
        backgroundImage: this.$container.css('background-image')
      }
    };

    this.opts.atTop = () => {
      this.$0.css({top: 'auto', left: 'auto', width: 'auto', padding: 0 });
    };

    this.opts.atBottom = () => {
      if (this.isBookingWithCondifenceDisplayed()) {
        this.$0.css({...this.opts.stick.css,...{
          top:  this.getFooterPosition(this.opts.stick.css.top) + 55 - this.$0.outerHeight(),
            left: this.$container.position().left,
          position: 'absolute'
        }});
      } else if ($_PAGE_.is('.packages-page')) {
        this.$0.css({...this.opts.stick.css,...{
          top: $_FOOTER_.position().top -  this.$0.outerHeight() - this.$0[0].querySelector('a.search-btn.btn-primary').offsetHeight - window.getComputedStyle(this.$0[0].querySelector('.button-container__adjusted-postion')).top.replace('px', '') - 175,
            left: this.$container.position().left,
            paddingBottom: '0px',
          position: 'absolute'
        }});
      } else {
        this.$0.css({...this.opts.stick.css,...{
          top: $_FOOTER_.position().top -  this.$0.outerHeight() - 125,
            left: this.$container.position().left,
          position: 'absolute'
        }});
      }
    };

    this.setBookWithConfidenceSticky();
    this.makeRateSummarySticky();
    $(window).scroll(this.scrollCb);
  }

  makeRateSummarySticky() {
    stickify(this.$0, this.opts);
    stickify(this.$bookWithConfidence, this.stickyOptsBookWithConfidence);
  }

  setBookWithConfidenceSticky() {
    this.stickyOptsBookWithConfidence.start = this.opts.start;
    if (this.opts.stick && this.opts.stick.css) {
      this.stickyOptsBookWithConfidence.end = this.getFooterStickyPosition(this.opts.stick.css.top);
    }
    //Top is a combination of the the original top and the rateSummary Height (including the padding which will be add when this is turned into sticky)
    let top;
    if(this.isResized && this.$bookWithConfidence.hasClass('stick')){
      this.$bookWithConfidence.removeClass('stick');
      top = this.opts.stick.css.top + this.$0.outerHeight();
    } else {
      top = this.opts.stick.css.top + this.$0.outerHeight() + parseInt(this.$parent.css('paddingTop')) + parseInt(this.$parent.css('paddingBottom'));
    }
    this.stickyOptsBookWithConfidence.stick = {
      css: {
        zIndex: 5,
        top: top,// + 107,
        left: this.$parent.offset().left,
        width: this.$container.width()
      }
    };

    this.stickyOptsBookWithConfidence.atTop = () => {
      this.$bookWithConfidence.css({top: 'auto', left: 'auto', width: 'auto'});
    };

    this.stickyOptsBookWithConfidence.atBottom = () => {

      this.$bookWithConfidence.css({
        top: this.getFooterPosition(this.opts.stick.css.top) + 55,
        left: this.$container.position().left,
        position: 'absolute'
      });
    };
  }

  isResizedBookwithConfidence() {
    let newResizedTop = this.opts.stick.css.top + this.$0.outerHeight();
    let top = this.stickyOptsBookWithConfidence.stick.css.top;
    if (newResizedTop !== top && this.$bookWithConfidence.hasClass('stick')) {
      this.stickyOptsBookWithConfidence.stick = {
        css: {
          zIndex: 5,
          top: newResizedTop,
          left: this.$parent.offset().left,
          width: this.$container.width()
        }
      };
      this.$bookWithConfidence.removeClass('stick');
    }
  }

  isBookingWithCondifenceDisplayed() {
    return this.$bookWithConfidence.length > 0 && !this.$bookWithConfidence.hasClass('hidden');
  }

  /* Returns the position from where the sticky animation should ends
   ** It takes in consideration: Footer position and takes off the padding of it,
   ** the floating header and the bookWithConfidence height
   */
  getFooterPosition(top) {
    return $_FOOTER_.offset().top - 136 - top - this.$bookWithConfidence.outerHeight();
  }

  getFooterStickyPosition(top){
    return $_FOOTER_.offset().top - 206 - top - this.$bookWithConfidence.outerHeight() - this.$0.outerHeight();
  }
}

class RateSummaryModify extends RateSummary {
  constructor() {
    super();

    this.handleCollapsedView();
    this.onModifyViewChange();
    this.onConfirmStep();
  }

  handleCollapsedView() {
    $('.res-rate-summary .property-name').addClass('toggle-mobile');
    $('.res-rate-summary .check-in-data').addClass('toggle-mobile');
    $('.res-rate-summary .rate-summary-content').addClass('toggle-mobile');
  }

  onModifyViewChange() {
    EventHandler.on('modify-view-changed', () => {
      if ($_PAGE_.is('.confirm')) {
        $('.res-rate-summary .property-name').removeClass('toggle-mobile')
          .show();
        $('.res-rate-summary .check-in-data').removeClass('toggle-mobile')
          .show();
        $('.res-rate-summary .rate-summary-content').removeClass('toggle-mobile')
          .show();
      }
    });
  }

  onConfirmStep() {
    EventHandler.on('modify-view-changed-to-confirm', () => {
      this.renderRateSummary();
    });
  }

  renderRateSummary() {
    let $el = $('.page-view-container .new-rate-summary'),
      $currentRateSummary = $('.current-rate-summary'),

      data = ModifySession.get(),

      nights = getNumDays(BBForm.getFrom(), BBForm.getTo());

    ModifySession.set({
      nights: nights
    });
    setCriteria({
      nights: nights
    });

    $el.find('.res-summary-img').html($currentRateSummary.find('.res-summary-img').html());
    $el.find('.property-name').html($currentRateSummary.find('.property-name').html());
    $el.find('.property-data').html($currentRateSummary.find('.property-data').html());
    $el.find('.check-in .time').html($currentRateSummary.find('.check-in .time').html());
    $el.find('.check-out .time').html($currentRateSummary.find('.check-out .time').html());

    BookingSummary.instantiate($el);

    BookingSummary.handleRooms(data.rooms);
    BookingSummary.handleAdults(data.adults);
    BookingSummary.handleChildren(data.children);
    BookingSummary.handleNights(nights);
    BookingSummary.handleCheckIn(BBForm.getFrom());
    BookingSummary.handleCheckOut(BBForm.getTo());
    BookingSummary.handleRoomType(data.description);
    BookingSummary.handleRoomDescription(data.shortName);
    BookingSummary.handleRatePlanDescription(data.RatePlanName ? data.RatePlanName : data.ratePlanName );
    BookingSummary.handleSubtotal(data.totalBeforeTax, data.currencyCode);
    BookingSummary.handleTotal(data.totalAfterTax, data.currencyCode);
    BookingSummary.handleTotalTax(parseFloat(data.totalAfterTax - data.totalBeforeTax).toFixed(2), data.currencyCode);
    BookingSummary.handleCashRP();
    BookingSummary.handleGiftCard(data.giftCard);
  }
}

let RateSummaryCurrent;

if (exists('.rate-summary-container')) {
  if ($_PAGE_.is('.booking-page') || $_PAGE_.is('.packages-page')) {
    RateSummaryCurrent = new RateSummaryBooking();
  } else if ($_PAGE_.is('.modify-page')) {
    RateSummaryCurrent = new RateSummaryModify();
  } else {
    RateSummaryCurrent = new RateSummary();
  }
}

export default RateSummaryCurrent;

$(document).ready(function() {
  let disclimerPhone = $('.phone-number');
  disclimerPhone.click((e)=>window.location.href = 'tel:' + e.target.textContent);

  if (reservationDataFromAEM.bnplComments === 'BNPL') {
    BNPL.handleConfig({
      currencyCode: reservationDataFromAEM.currencyCode,
      checkinDate: formatDateForUplift(reservationDataFromAEM.checkInDate),
      checkoutDate: formatDateForUplift(reservationDataFromAEM.checkOutDate),
      checkout: false,
      checkoutConfirm: reservationDataFromAEM.bnplComments
    });
  }
  if (reservationDataFromAEM.channel === "partner") {
    $('.res-sum-snow-storm').removeClass('hidden');
  }



    // JS logic to remove hide class from booking widget based on the configration
  const bookingSummaryWidget = document.querySelector('.booking-summary-widget');
  if (bookingSummaryWidget) {
    const updateBookingSummaryWidgetClasses = (deviceArray) => {
      if (!bookingSummaryWidget) {
          console.error('Element with class "bookingSummaryWidget" not found');
          return;
      }
      const classMap = {
          'desktop': 'visible-md',
          'tablet': 'visible-sm',
          'mobileweb': 'visible-xs'
      };
      // Remove 'hide' class
      bookingSummaryWidget.classList.remove('hide');

      // Remove any existing visibility classes
      Object.values(classMap).forEach(cls => bookingSummaryWidget.classList.remove(cls));

      // Add visibility classes based on the deviceArray
      deviceArray.forEach(device => {
          if (classMap[device]) {
              bookingSummaryWidget.classList.add(classMap[device]);
          }
      });
  };

    const pageConfig = (window.wyndham && window.wyndham.configs && window.wyndham.configs["bsw-config"] && window.wyndham.configs["bsw-config"].configs && window.wyndham.configs["bsw-config"].configs[0]) || false;
    if (pageConfig) {

      const isLocaleValid = pageConfig.locales.includes(_LOCALE_) || pageConfig.locales.includes(_SEO_LOCALE_);
      if(isLocaleValid) {
        updateBookingSummaryWidgetClasses(pageConfig.channels);
      }

    }

  }

  const roomSumaryModalElement = document.getElementById('roomSummaryDetail');
  if (roomSumaryModalElement) {

    // Function to add dynamic content inside room summary modal
    const roomDetailsContent = (name, checkInTime, checkOutTime, adults,
      checkInDate, checkOutDate, children, nights, description, hotelAddressLine, hotelCity, hotelCountryName, phone1, roomCount, hotelPostalCode) => {
      try {
        return `<div class="bsw-room-details__address">
            <h4 class="headline-c">${name}</h4>
            <p>${checkShouldHideProvinceCountry(hotelAddressLine,hotelCity,hotelCountryName,hotelPostalCode)}</p>
            <p><a href=${"tel:" + phone1}>+${phone1}</a></p>
          </div>
          <div class="bsw-room-details__dateandmember">
            <p>${formatDateForPrinting(checkInDate, 'weekdayCompact')} - ${formatDateForPrinting(checkOutDate, 'weekdayCompact')}</p>
            <p>${roomCount} ${roomCount > 1 ? 'ROOMS' : 'ROOM'}, ${nights} ${nights > 1 ? 'NIGHTS' : 'NIGHT'}</p>
            <p>${adults} ${adults > 1 ? "ADULTS" : "ADULT"} , ${children} CHILDREN </p>
          </div>
          <div class="bsw-room-details__duration">
            <div class="bsw-room-details__duration-check">
              <p><strong>Check-In</strong></p>
              <p>After ${formatTimeShow(checkInTime)}</p>
            </div>
            <div class="bsw-room-details__duration-check">
              <p><strong>Check-In</strong></p>
              <p>After ${formatTimeShow(checkOutTime)}</p>
            </div>
          </div>
          <div class="bsw-room-details__amenities">
            <p><strong>Room Details</strong></p>
            <p>${description}</p>
      </div>
  `;
      } catch (err) {
        console.error(err, "Error from roomDetaisContent Function");
      }
    };

    /**
     * Callback function for room summary modal
     */
    $('#roomSummaryDetail').on('shown.bs.modal', function() {
      const currentSection = $(this).find('.modal-section');
      try {
        // method to check if roomType exist or not
        const checkForObjectExistence = (digitalData) => {
          return !!(digitalData &&
            digitalData.booking &&
            digitalData.booking.bookingInfo &&
            digitalData.booking.bookingInfo.roomType
          );
        };
        const { name, checkInTime, checkOutTime, hotelAddressLine, hotelCity, hotelCountryName, phone1, hotelPostalCode } = getSessionStorage("searchOverview") || {};
        const { adults, checkInDate, checkOutDate, children, nights, rooms: roomCount } = getSessionStorage("criteria") || {};
        const { rooms } = getSessionStorage("roomsRates") || {};
        const { booking: { bookingInfo: { roomType } } } = getSessionStorage("digitalData") || {};
        // below room type value should be populated when it's not available in Session
        const roomTypeValue = !roomType && checkForObjectExistence(window.digitalData) && window.digitalData.booking.bookingInfo.roomType || '';
        // function for room type
        const getRoomtype = (roomType, rooms) => {
          const matchedRooms = rooms.filter(item => item.roomTypeCode === roomType);
          return matchedRooms.length > 0 ? matchedRooms[0].description : '';
        };

        const description = getRoomtype((roomType || roomTypeValue), rooms);
        $(currentSection).html(roomDetailsContent(
          name,
          checkInTime,
          checkOutTime,
          adults,
          checkInDate,
          checkOutDate,
          children,
          nights,
          description,
          hotelAddressLine,
          hotelCity,
          hotelCountryName,
          phone1,
          roomCount,
          hotelPostalCode
        ));
      } catch (err) {
        console.error(err, "Error from Room Summary callback.");
      }  
    });

  }
  const checkShouldHideProvinceCountry = (hotelAddressLine, hotelCity, hotelCountryName, hotelPostalCode) => {

    let shouldHideProvinceCountry = HideProvinceCountryConfig.getHideProvinceCountryConfig(getSessionCriteria().propertyId);

    let address = shouldHideProvinceCountry ? (hotelAddressLine + ', ' + hotelCity + ' ' + hotelPostalCode) : (hotelAddressLine + ', ' + hotelCity + ', ' + hotelCountryName + ', ' + hotelPostalCode);

    return address;
  };
});
