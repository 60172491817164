/* eslint-disable no-undef */
import Cookie from '../wr/base/cookie.js';
import { exists, isDesktopWidth } from '../base/dom-utils.js';
// WILLBE: import { getServiceUrl, EventHandler } from '../base/utils.js';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import ConfigsUtils from '../base/aem-configs/config-utils.js';
import { ACCESS_TOKEN, AUTH_LANGUAGE, AUTH_LANGUAGE_LOCALE, LOGIN_REDIRECT, OKTA_AUD, REFRESH_TOKEN, WR_AUTHENTICATED, _LOCALE_, _OKTA_LOCALE_, _defaultChannelId, SESSION_PROFILE, isPublish} from '../base/vars.js';
import { UserHandler, getSessionStorage, setSessionStorage, getLocalStorage, setLocalStorage, refreshAfterSSOLogin, bookDataBeforeSSOLogin, goToPackages, refreshAfterSSOLogout } from '../base/session-handler.js';
import { getISOFormatDate, getXDays, CookieHandler, EventHandler, getHrefESApiEncoded, callService } from '../base/utils.js';
import OktaClient from './okta-client.js';
import Analytics from '../base/wyn-analytics-module.js';
let oktaSigninClient = null;
import Reservations from '../wr/components/reservations.js';

/**
 * Initializes the Okta Sign-in client
 */
class Login {
  constructor() {
    if (isPublish) {
      this.langRedirect();
    }

    if(Cookie.getCookie('WHG_AUTH_ID') || Cookie.getCookie('WHG_REFRESH') || Cookie.getCookie('WHG_SESSION')){
      Cookie.deleteCookie('WHG_AUTH_ID');
      Cookie.deleteCookie('WHG_REFRESH');
      Cookie.deleteCookie('WHG_SESSION');
      Cookie.deleteCookie('WRAuthenticated');

      // Refresh the page
      location.reload();
    }

    $(() => {
      this.assignTriggerEventForSignout();
      window.onload = async() => await this.oktaSignin();
      setTimeout(() => {
        this.assignEventsForSignIn();
      }, 2000);
      // this.assignEventsForSignIn();
      this.assignEventsForSignOut();
    });
    if (!navigator.userAgent.includes('Chrome') && navigator.userAgent.includes('Safari')) {
      $(".btn-reg-promo").removeAttr('href').css("cursor","pointer").addClass('signin-button');
      $("a[href='#login']").removeAttr('href').css("cursor","pointer").addClass('signin-button');
    }
  }

  getOktaUrl() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_domain_url' && item.value ? item.value : result;
      },
      null
    );
  }
  getOktaClientId() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_clientId' && item.value ? item.value : result;
      },
      null
    );
  }
  getRefreshToken() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_clientId' && item.value ? item.value : result;
      },
      null
    );
  }

  async getDatesToDisplay() {
    // Set dateTo (futureDate) to a year from today to show future dates- can't book more than a year out
    let futureDate = getISOFormatDate(getXDays(366, new Date())),
      todayDate = new Date(),
      selectedPastDate,
      filterValue = $('#timeFilter').val();

    // Get date based on selection
    if (filterValue == 'd30') {
      selectedPastDate = getISOFormatDate(getXDays(-30, todayDate));
    } else if (filterValue == 'd90') {
      selectedPastDate = getISOFormatDate(getXDays(-90, todayDate));
    } else if (filterValue == 'm12') {
      // Change calendar year -1
      let d = todayDate,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = (d.getFullYear() - 1);
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      selectedPastDate = [year, month, day].join('-');
    } else {
      // If m18 or null, then show all (max stored is 18 months)
      let d = todayDate,
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = (d.getFullYear() - 2);
      if (month.length < 2) month = '0' + month;
      if (day.length < 2) day = '0' + day;
      selectedPastDate = [year, month, day].join('-');
    }

    let dates = {
      selectedPastDate: selectedPastDate,
      futureDate: futureDate
    };
    return (dates);
  }

  addToBookingHistory(bookingHistory, source, sourceKey, targetKey, transformValue = false) {
    if (source.hasOwnProperty(sourceKey)) {
      bookingHistory[targetKey] = transformValue ? (source[sourceKey].length > 0 ? 'true' : 'false') : source[sourceKey];
    }
  }

  async getFavoritePropIds() {
    const refreshToken = CookieHandler.readCookie('refresh_token');
    const accessToken = CookieHandler.readCookie('access_token');
    const timestamp = Date.now();
    const user = JSON.parse(localStorage.getItem('OT_WHG_SESSION'));
    const Cookie = `refresh_token=${refreshToken};access_token=${accessToken};loglocale={"timestamp":${timestamp},"serviceVersion":"1.0","pageHashCode":"/WHGServices/loyalty/member/verifyEmail?language=en-us","language":"en-us","channel":"responsive","seed":"DE47431A-BC2F-429E-8785-EA881682D951"};`;
    const headers = {
      'Content-Type': 'application/json',
      Cookie: Cookie,
    };
    const payload = {
      uniqueId: user && user.membershipId,
      type: '',
      action: 'GET',
    };
    let res = await callService('myFavourite', JSON.stringify(payload), 'POST', headers);
    return res;
  }

  async oktaSignin() {
    // configure okta client with domain and clientID settings
    if(!oktaSigninClient){
      oktaSigninClient = await OktaClient.getOktaClient();
    }
    let isAuthenticated = await oktaSigninClient.isAuthenticated();
    this.redirectViaOktaLoginFlow(isAuthenticated);
    console.log("oktaSignin::isAuthenticated", isAuthenticated);
    if (isAuthenticated || CookieHandler.readCookie(WR_AUTHENTICATED) === 'true') {
      const isValid = await OktaClient.validateOktaToken();
      console.log("oktaSignin::isValid", isValid);
      if (isValid) {
        //window.history.replaceState({}, document.title, window.location.pathname);
        //update the UI for signed-in/ authenticated user.
        this.handleAuthenticatedUser();
      }
      return;
    }
    if (getLocalStorage('postLogoutPackagesRedirect')) {
      const preSignoutUrl = getLocalStorage('originalUrl', 'logoutSourceURL');
      const bookdata = getLocalStorage('bookingData');
      localStorage.removeItem('postLogoutPackagesRedirect');
      localStorage.removeItem('originalUrl');
      goToPackages(bookdata.rt, bookdata.rp, bookdata.cc, bookdata.ae, bookdata.qp, bookdata.pacRate, bookdata.at, '', false, bookdata.directBillSupported,preSignoutUrl, bookdata);
    } else if (getLocalStorage('postLogoutRedirect')) {
      const preSignoutUrl = getLocalStorage('originalUrl', 'logoutSourceURL');
      localStorage.removeItem('postLogoutRedirect');
      localStorage.removeItem('originalUrl');
      location.href = preSignoutUrl;
    }
    //Member just completed the login at Okta and Wyndham Website needs to complete the login process by doing the handleredirectcallback
    const query = window.location.search;
    const encodedURLEsapi = getHrefESApiEncoded();
    const decodeURI = decodeURIComponent(encodedURLEsapi);
    const shouldParseResult = query.includes('code=') && query.includes('state=');
    console.log("oktaSignin::shouldParseResult", shouldParseResult);
    if (shouldParseResult) {
      try {
        // call handleRedirect callback to insure completion of Sign-in
        const result = await oktaSigninClient.handleRedirectCallback(decodeURI);
        console.log("oktaSignin::result", result);
        // create backup storage Auth0 for Safari browser
        OktaClient.createBackupAuth0LocalStorage();
        if (result.appState && result.appState.targetUrl) {
          console.log("oktaSignin::result.appState", result.appState);
          const oktaLanguageLocaleCookie = CookieHandler.getCookie(AUTH_LANGUAGE_LOCALE);
          const convertedOktaLocaleCookie = this.oktaLanguagetoWyndhamLanguage(oktaLanguageLocaleCookie);
          console.log("oktaSignin::convertedOktaLocaleCookie", convertedOktaLocaleCookie);
          if (convertedOktaLocaleCookie) {
            document.cookie = AUTH_LANGUAGE_LOCALE + '=; domain=.wyndhamhotels.com; path=/; secure';
            const topLevelDomain = '.com';
            const splitTargetUrl = result.appState.targetUrl.split(topLevelDomain);
            const newTargetUrl = splitTargetUrl[0] + topLevelDomain + '/' + convertedOktaLocaleCookie + splitTargetUrl[1];
            refreshAfterSSOLogin(newTargetUrl);
          } else {
            refreshAfterSSOLogin(result.appState.targetUrl);
          }
        } else {
          window.history.replaceState({}, document.title, window.location.pathname);
        }
      } catch (err) {
        console.log("oktaSignin::catch", err);
        const loginRedirect = getSessionStorage(LOGIN_REDIRECT);
        sessionStorage.removeItem(LOGIN_REDIRECT);
        this.redirectToOktaLogin(loginRedirect.appStateTarget);
      }
    }
    isAuthenticated = await oktaSigninClient.isAuthenticated();
    console.log("oktaSignin::isAuthenticated", isAuthenticated);
    if (isAuthenticated) {
      this.handleAuthenticatedUser();
    } else {
      this.handleUnAuthenticatedUser();
    }
  }

  /**
   * handle / update UI post successful Okta sign-in.
   */
  async handleAuthenticatedUser() {
    const bookingHistory = {};
    const urlParams = new URLSearchParams(window.location.search),
      isMfaLoginRedirect = urlParams.get('flow') === 'mfaLogin',
      isVerifyPage = window.location.href.includes('verify-your-identity'),
      isRedirectPage = window.location.href.includes('wyndham-rewards/auth-after-sso');
    if (getSessionStorage('triggerEvent1') && !isMfaLoginRedirect && !isVerifyPage && !isRedirectPage) {
      sessionStorage.removeItem('triggerEvent1');
      try {
        Analytics.satelliteTracker('signInAuthenticated');
      } catch (error) {
        console.error('Event1 error - ', error);
      }
    }
    if(!oktaSigninClient){
      oktaSigninClient = await OktaClient.getOktaClient();
    }
    $('.wyndham-rewards-user-details').removeClass('hidden');
    $('.dropdown-toggle-myacc').hide();
    const claims = await oktaSigninClient.getIdTokenClaims();
    const userProfile = await oktaSigninClient.getUser();
    if (!claims) {
      return;
    }
    //let memberNumber = claims.membership_number;
    const cache = getLocalStorage(
      `@@auth0spajs@@::${claims.aud}::${this.getOktaAudience().toString()}::${this.getOktaScope()}`
    );
    if (cache) {
      const accessToken = cache.body.access_token;
      const refreshToken = cache.body.refresh_token;
      CookieHandler.createCookie(ACCESS_TOKEN, accessToken, 1);
      CookieHandler.createCookie('expiresAt', cache.expiresAt, 1);
      CookieHandler.createCookie(REFRESH_TOKEN, refreshToken, 1);
      setSessionStorage(OKTA_AUD, {aud: claims.aud});

      // Set WR_AUTHENTICATED after login, and set to trigger refreshAfterLogin after redirected
      if (!CookieHandler.readCookie(WR_AUTHENTICATED) && !isRedirectPage) {
        setSessionStorage('triggerEvent1', true);
        UserHandler.resetLocalStorage();
        UserHandler.refreshAfterLogin();
      } else if (!getLocalStorage(SESSION_PROFILE) && !isRedirectPage) {
        UserHandler.updateSessionCookie(() => {
          EventHandler.send('sign-in-success');
        }, true);
      } else if (getLocalStorage(SESSION_PROFILE) && !isRedirectPage) {
        EventHandler.send('sign-in-success');
      }
    }
    let nickname = userProfile.nickname;
    $('.wr-logged-user-name').each(function() {
      $(this).html(($(this).data('welcomeBack') ? $(this).data('welcomeBack') + ', ' : '') + nickname);
    });
    $('#wrUserFirstName').html(nickname);
    $('#member-no').html(claims.membership_number);
    $('.wyndham-rewards-links').addClass('hidden');
    if (!isDesktopWidth() && !$('.wyndham-rewards-logged-in').hasClass('login-show')) {
      $('.wyndham-rewards-logged-in').addClass('login-show');
    }

    const favHotels = await this.getFavoritePropIds();
    const dates = await this.getDatesToDisplay();
    const filterCriteria = favHotels.FilterCriteria && favHotels.FilterCriteria.length ? favHotels.FilterCriteria[0] : null;
    if(favHotels && (favHotels.FilterCriteria || favHotels.FavoriteHotels || favHotels.RecentSearches)) {
      setSessionStorage('_userpreferences_', JSON.stringify(favHotels), true);
      const customEvent = new CustomEvent('userpreferences-success', {
        detail: { ...favHotels },
        bubbles: true
      });
      document.dispatchEvent(customEvent);
    }
    if(filterCriteria) {
      const customEvent = new CustomEvent('srp.user.preferences.filterCriteria', {
        detail: {
          ...filterCriteria
        }
      });
      const localCorpCode = localStorage.getItem('userCorporateCode');
      if((localCorpCode === null || localCorpCode === undefined) && filterCriteria.corporateCode) {
        setLocalStorage('userCorporateCode', filterCriteria.corporateCode);
        EventHandler.send('userCorporateCode', null, filterCriteria.corporateCode);
      }
      document.dispatchEvent(customEvent);
    }
    Reservations.getReservations(dates.selectedPastDate, dates.futureDate)
      .done((response) => {
        if (response.body) {
          const data = response.body;
          if (typeof data.reservations === 'object') {
            this.addToBookingHistory(bookingHistory, data.reservations, 'past', 'past');
            this.addToBookingHistory(bookingHistory, data.reservations, 'future', 'future');
          }
          if (typeof favHotels === 'object') {
            this.addToBookingHistory(bookingHistory, favHotels, 'FavoriteHotels', 'favoriteHotels', true);
            this.addToBookingHistory(bookingHistory, favHotels, 'FilterCriteria', 'bedQuantity');
          }
          if (!sessionStorage.getItem('bookingHistory') || sessionStorage.getItem('bookingHistory')) {
            sessionStorage.setItem('bookingHistory', JSON.stringify(bookingHistory));
          }
        }
      }).always(() => {
        $(document).trigger('getResData:always');
      });
  }
  async handleUnAuthenticatedUser() {
    $('.wyndham-rewards-links').removeClass('hidden');
    if ($('.wyndham-rewards-logged-in').hasClass('login-show')) {
      $('.wyndham-rewards-logged-in').removeClass('login-show');
    }
    if (sessionStorage.getItem('bookingHistory') || sessionStorage.getItem('bookingHistory') === null) {
      sessionStorage.removeItem('bookingHistory');
    }
    if (sessionStorage.getItem('_userpreferences_') || sessionStorage.getItem('_userpreferences_') === null) {
      sessionStorage.removeItem('_userpreferences_');
    }
  }

  getOktaAudience() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_audience' && item.value ? item.value : result;
      },
      null
    );
  }
  getOktaRedirectUri() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_redirect_uri' && item.value ? item.value : result;
      },
      null
    );
  }

  getMfaAuthUri() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_mfa_auth_uri' && item.value ? item.value : result;
      },
      null
    );
  }

  getBlankPageRedirect() {
    // const blankPageRedirectUrl = this.getOktaRedirectUri() + '/wyndham-rewards' + templateUrls.redirectPage;
     const blankPageRedirectUrl = this.getOktaRedirectUri() + '/wyndham-rewards/auth-after-sso';

    return blankPageRedirectUrl;
  }

  getOktaRedirectUriWithBrand() {
    let okta_redirect_uri = this.getOktaRedirectUri();
    if (getBrand() != 'wyndham-hotel-group') {
      okta_redirect_uri = this.getOktaRedirectUri() + '/' + getBrand();
    }
    return okta_redirect_uri;
  }

  getOktaScope() {
    return ConfigsUtils.getConfigs('oktaSignInConfigInfo', _defaultChannelId, [_LOCALE_], ConfigsUtils.Any).reduce(
      (result, item) => {
        return item.name === 'okta_scope' && item.value ? item.value : result;
      },
      null
    );
  }

  async mfaOktaLogin() {
    try {
      if(!oktaSigninClient){
        oktaSigninClient = await OktaClient.getOktaClient();
      }

      let oktaAuthorizationParams = {
        redirect_uri: this.getBlankPageRedirect() + '?flow=mfaLogin',
        audience: this.getMfaAuthUri(),
        scope: 'enroll',
        ui_locales: _OKTA_LOCALE_,
      };
      setLocalStorage("mfa-local", { locale: _LOCALE_ });
      await oktaSigninClient.loginWithRedirect({ authorizationParams: oktaAuthorizationParams});

    } catch (error) {
      console.error('mfaOktaLogin error - ', error);
    }
  }

  oktaLanguageCodetoWyndhamLocale(languageCode) {
    const oktaWyndhamLanguageMap = {
      'en': 'en-us',
      'fr': 'fr-ca',
      'zh': 'zh-cn',
      'es': 'es-xl',
      'pt': 'pt-br',
      'de': 'de-de',
      'ko': 'ko-kr',
      'tr': 'tr-tr',
    };

    return oktaWyndhamLanguageMap[languageCode];
  }

  oktaLanguagetoWyndhamLanguage(languageCode) {
    const oktaWyndhamLanguageMap = {
      'en_US': 'en-us',
      'en_GB': 'en-uk',
      'en_CA': 'en-ca',
      'en_AP': 'en-ap',
      'fr_CA': 'fr-ca',
      'zh_CN': 'zh-cn',
      'es_ES': 'es-xl',
      'pt_PT': 'pt-br',
      'de_DE': 'de-de',
      'ko_KR': 'ko-kr',
      'tr_TR': 'tr-tr',
    };

    return oktaWyndhamLanguageMap[languageCode];
  }

  async redirectToOktaLogin(targetURL) {

    UserHandler.logout();
    if(!oktaSigninClient){
      oktaSigninClient = await OktaClient.getOktaClient();
    }
    const urlParams = new URLSearchParams(window.location.search),
    isFtsiLoginRedirect = urlParams.get('flow') === 'ftsiLogin';
    const currentBrand = digitalData.page.category.brand;
    const currentLang = digitalData.page.pageInfo.language;
    const geoRegion = digitalData.page.pageInfo.geoRegion;
    const effectiveDate = digitalData.page.pageInfo.effectiveDate;
    const iataCode = digitalData.search.searchInfo.iataCode;
    const referringBrand = digitalData.page.pageInfo.referringBrand;
    const authenticatedUser = digitalData.user.userInfo.authenticated;

    const capturedURL = window.location.search;
    const urlParameters = new URLSearchParams(capturedURL);
    const parameterICID = urlParameters.get('ICID') ? urlParameters.get('ICID') : '';
    const parameterECID = urlParameters.get('ECID') ? urlParameters.get('ECID') : '';
    const parameterMCID = urlParameters.get('MCID') ? urlParameters.get('MCID') : 'okta';
    const oktaLanguageCookie = CookieHandler.getCookie(AUTH_LANGUAGE);

    let oktaAuthorizationParams = {
      // redirect_uri: this.getOktaRedirectUriWithBrand(),
      redirect_uri: this.getBlankPageRedirect(),
      audience: this.getOktaAudience(),
      'ext-brand': brand_id,
      'ext-AA1': `brand:::${currentBrand}.lang:::${currentLang}.geoRegion:::${geoRegion}.effDate:::${effectiveDate}.iataCode:::${iataCode}.refBrand:::${referringBrand}.authenticated:::${authenticatedUser}`,
      'ext-AA2': `ICID:::${parameterICID}.ECID:::${parameterECID}`,
      'ext-MCID': parameterMCID,
      scope: this.getOktaScope(),
      ui_locales: isFtsiLoginRedirect ? oktaLanguageCookie : (_OKTA_LOCALE_ || oktaLanguageCookie)
    };
    CookieHandler.eraseCookie(AUTH_LANGUAGE);


    // appState to keep track of current page url,
    // and use the current page brand and language.
    const appStateTarget = targetURL ? targetURL : window.location.href;
    const options = {
      authorizationParams: oktaAuthorizationParams,
      appState: { targetUrl: appStateTarget },
    };
    setSessionStorage(LOGIN_REDIRECT, { appStateTarget: appStateTarget });
    await oktaSigninClient.loginWithRedirect(options);
  }

  redirectToProfileSetUp() {
    const authLanguageCode = CookieHandler.getCookie(AUTH_LANGUAGE);
    const localeCode = authLanguageCode ? this.oktaLanguageCodetoWyndhamLocale(authLanguageCode) : _LOCALE_;
    let okta_redirect_uri = this.getOktaRedirectUri() + '/' + localeCode + '/' + getBrand() + '/' + 'first-time-sign-in/base/okta/profile-setup';
    return okta_redirect_uri;
  }

  langRedirect(){
    const oktaWyndhamLanguageMap = {
      'en_US': 'en-us',
      'en_GB': 'en-uk',
      'en_CA': 'en-ca',
      'en_AP': 'en-ap',
      'fr_CA': 'fr-ca',
      'zh_CN': 'zh-cn',
      'es_ES': 'es-xl',
      'pt_PT': 'pt-br',
      'de_DE': 'de-de',
      'ko_KR': 'ko-kr',
      'tr_TR': 'tr-tr',
    };
    const oktaLanguageCookie = CookieHandler.getCookie("wrUserPreferredLanguage");
    let currentLang = Object.values(oktaWyndhamLanguageMap).find(lang => window.location.pathname.includes(lang));
    if (currentLang || !oktaLanguageCookie) {
      return;
    }

    if (oktaLanguageCookie !== 'en-us' && !window.location.pathname.includes("/bin/")){
      if (window.location.pathname.includes("auth-after-sso")) {
        return;
      }

      if (!window.location.pathname.includes(oktaLanguageCookie) && brand_id == 'wr'
       && (document.referrer.indexOf(location.protocol + '//' + location.host) === 0 || !document.referrer)
      ) {
        window.location = this.getOktaRedirectUri() + '/' + (oktaLanguageCookie === 'en-gb' ? 'en-uk' : DOMPurify.sanitize(oktaLanguageCookie)) + window.location.pathname;
      } else {
        Cookie.deleteCookie('wrUserPreferredLanguage');
      }
    }
  }

  assignEventsForSignIn() {
    // Assign login event handlers.
    $('.signin-button').on('click', async(e) => {
      if ( digitalData.page.pageInfo.language !== Cookie.getCookie('auth_language') ) {
        CookieHandler.eraseCookie('auth_language');
        CookieHandler.createCookie('auth_language', digitalData.page.pageInfo.language, 1);
      }
      bookDataBeforeSSOLogin();
      e.preventDefault();
      this.redirectToOktaLogin(null);
    });

    $('#sign-in-link').on('click', async(e) => {
      bookDataBeforeSSOLogin();
      e.preventDefault();
      this.redirectToOktaLogin(null);
    });

    $('a[href$="signin-link-earn-free-night"]').on('click', async(e) => {
      bookDataBeforeSSOLogin();
      e.preventDefault();
      this.redirectToOktaLogin(null);
    });

    $('a[href$="sign-in-link"]').on('click', async(e) => {
      bookDataBeforeSSOLogin();
      e.preventDefault();
      this.redirectToOktaLogin(null);
    });

    $('a[href$="#login"]').on('click', async(e) => {
      bookDataBeforeSSOLogin();
      e.preventDefault();
      this.redirectToOktaLogin(null);
    });
  }

  async redirectViaOktaLoginFlow(isAuthenticated) {
    // this flow is used by Salesforce and when the user changes their password in Okta
    const urlParams = new URLSearchParams(window.location.search),
      isPhApp = urlParams.has('platform') && urlParams.has('adobeID'),
      isLoginRedirect = urlParams.get('flow') === 'login',
      isMfaLoginRedirect = urlParams.get('flow') === 'mfaLogin',
      isFtsiLoginRedirect = urlParams.get('flow') === 'ftsiLogin';

    const query = window.location.search;
    const decodeURI = decodeURIComponent(window.location.href);
    const shouldParseResult = query.includes('code=') && query.includes('state=');
    if (isMfaLoginRedirect && shouldParseResult) {
      try {
        const mfaLocale = getLocalStorage('mfa-local') || _LOCALE_;
        const localVerifyIdentityPage = '/' + mfaLocale.locale + '/wyndham-rewards/first-time-sign-in/base/okta/verify-your-identity';
        await oktaSigninClient.handleRedirectCallback(decodeURI);
        localStorage.removeItem('mfa-local');
        window.history.replaceState({}, document.title, window.location.pathname);
        window.location.assign(localVerifyIdentityPage);
      } catch (error) {
        console.error('mfaLoginRedirect error - ', error);
      }
    }

    // const urlRedirectPage = templateUrls.redirectPage,
    const urlRedirectPage = 'wyndham-rewards/auth-after-sso',
      isRedirectPage = window.location.href.includes(urlRedirectPage);

    if (isRedirectPage && !isPhApp && isFtsiLoginRedirect) {
      if (!isAuthenticated) {
        this.redirectToOktaLogin(this.redirectToProfileSetUp());
      } else if (isAuthenticated) {
        window.location.assign(this.redirectToProfileSetUp());
      }
    }

    if (isRedirectPage && !isPhApp && isLoginRedirect) {
      if (urlParams.has('redirectTo')) {
        let redirectUrl = urlParams.get('redirectTo');
        if (urlParams.has('code')) {
          redirectUrl = redirectUrl + '?code=' + urlParams.get('code');
        }
        if (!isAuthenticated) {
          this.redirectToOktaLogin(redirectUrl);
        } else if (isAuthenticated) {
          window.location.assign(redirectUrl);
        }
      } else if (!isAuthenticated) {
        this.redirectToOktaLogin(this.getOktaRedirectUriWithBrand());
      } else if (isAuthenticated) {
        window.location.assign(this.getOktaRedirectUriWithBrand());
      }
    }
  }

  fetchMfaAccessTokenFromLocalStorage(){
    let key, mfaAccessToken;

    for (let n = 0, len = localStorage.length; n < len; ++n) {
      const thisKey = localStorage.key(n);
      if (thisKey.includes('@@auth0spajs@@') && thisKey.includes('/mfa/') && thisKey.includes('enroll')) {
        key = thisKey;
        break;
      }
    }
    if (key) {
      const value = JSON.parse(localStorage.getItem(key));
      mfaAccessToken = value.body.access_token;
    }
    return mfaAccessToken;
  }

  fetchAccessTokenFromLocalStorage(){
    let key, accessToken;

    for (let n = 0, len = localStorage.length; n < len; ++n) {
      const thisKey = localStorage.key(n);
      if (thisKey.startsWith('@@auth0spajs@@') && thisKey.includes(this.getOktaAudience()) && thisKey.includes(this.getOktaScope())) {
        key = thisKey;
        break;
      }
    }
    if (key) {
      const value = JSON.parse(localStorage.getItem(key));
      accessToken = value.body.access_token;
    }
    return accessToken;
  }

  deleteMfaAccessTokenFromLocalStorage(){
    let key;
    for (let n = 0, len = localStorage.length; n < len; ++n) {
      const thisKey = localStorage.key(n);
      if (thisKey.includes('@@auth0spajs@@') && thisKey.includes('/mfa/') && thisKey.includes('enroll')) {
        key = thisKey;
        break;
      }
    }
    if (key) {
      localStorage.removeItem(key);
    }
  }

  assignEventsForSignOut() {
    const loginUserButton = $('.login-user-button'),
      signoutButtons = $('.sign-out-button'),
      signoutLinks = $('[href="#logout"]'),
      returnToAppButton = $('.mobileCompleteButton');

    loginUserButton.on('click', e => {
      e.preventDefault();
      bookDataBeforeSSOLogin();
      this.callSignOutAPI();
    });

    signoutLinks.each((i, btn) => {
      btn.addEventListener('click', e => {
        e.preventDefault();
        bookDataBeforeSSOLogin();
        this.callSignOutAPI();
      });
    });

    signoutButtons.each((i, btn) => {
      btn.addEventListener('click', e => {
        e.preventDefault();
        bookDataBeforeSSOLogin();
        this.callSignOutAPI();
      });
    });

    if(exists(returnToAppButton)){
      returnToAppButton.on('click', e => {
        e.preventDefault();
        // WHRAPP-8341 - cleare local storage and session storage post
        document.cookie = ACCESS_TOKEN + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure';
        document.cookie = REFRESH_TOKEN + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure';
        document.cookie = WR_AUTHENTICATED + '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT; secure';
        localStorage.clear();
        sessionStorage.clear();
        bookDataBeforeSSOLogin();
        this.callSignOutAPI(e.target.href);
      });
    }
  }

  assignTriggerEventForSignout() {
    EventHandler.one(EventHandler.profile.oktaSignOut, () => {
      bookDataBeforeSSOLogin();
      this.callSignOutAPI();
    });
  }

  async callSignOutAPI(signOutSourcePageUrl) {
    const refreshToken = CookieHandler.getCookie(REFRESH_TOKEN);
    if(refreshToken){
      await fetch(this.getOktaUrl() + '/oauth/revoke', {
        method: 'POST',
        headers: {
          'content-type': 'application/json',
        },
        body: JSON.stringify({
          'client_id': this.getOktaClientId(),
          'token': refreshToken
        }),
      }).then((response) => {
        response.text();
        UserHandler.logout();
      });
    }
    await this.logout(signOutSourcePageUrl);
  }

  async logout(signOutSourcePageUrl) {
    if(!oktaSigninClient){
      oktaSigninClient = await OktaClient.getOktaClient();
    }
    await oktaSigninClient.cacheManager.clear(),
    oktaSigninClient.cookieStorage.remove(oktaSigninClient.orgHintCookieName, {
      cookieDomain: oktaSigninClient.options.cookieDomain

    }),
    oktaSigninClient.cookieStorage.remove(oktaSigninClient.isAuthenticatedCookieName, {
      cookieDomain: oktaSigninClient.options.cookieDomain
    }),
    oktaSigninClient.userCache.remove("@@user@@");
    const signOutRedirectUrl = signOutSourcePageUrl ? signOutSourcePageUrl : window.location.href;
    refreshAfterSSOLogout(signOutRedirectUrl);
    oktaSigninClient.logout({
      logoutParams: {
        // returnTo: this.getOktaRedirectUriWithBrand(),
        returnTo: this.getBlankPageRedirect(),
      },
    });
    oktaSigninClient = null;
    OktaClient.resetOktaclient();
  }
}



export default new Login();
let LoginHandler = new Login();
export { LoginHandler };
