import { getBrand } from './session-handler.js';
import { _isNotNull, _isNumber, EventHandler } from './utils.js';
import { $_PAGE_, isPublish } from './vars.js';

class Thinking {
  constructor() {
    this.count = 0;
    this.total = 0;
    this.ignore = [
      'getCriteria',
      'propertypages.json',
      'autocomplete',
      'autocomplete.json',
      'geocode',
      'placequery',
      'nor1'
    ];

    this.timing = 30000;
    this._didThings = false;
    this._pageLoad = true;
  }
  init(){
    EventHandler.send(EventHandler.serviceCall.start);

    this.start();

    if (isPublish === true) {
      this.listen();
    }

    EventHandler.one(EventHandler.criteria.init, () => {
      this.end();
    });
  }
  addIgnore(request, _orRemoveIfExists) {
    let index = this.ignore.indexOf(request);
    if (index === -1) {
      this.ignore.push(request);
    } else if (index > -1 && _orRemoveIfExists === true) {
      this.ignore.splice(index, 1);
    }
  }

  tooLong() {
    $_PAGE_.removeClass('loading');
  }

  start(surl) {
    if (++this.count === 1) {
      this._didThings = true;

      if (!(this._pageLoad)) {
        this.timeout = setTimeout(() => this.tooLong(), this.timing);
      }

      if ($_PAGE_.hasClass('search-results-page') && !$_PAGE_.hasClass('search-results-page-v2')) {
        if (surl && (surl.match(/getSearchPOI/) || surl.match(/propertyDataList/))) {
          //do nothing
        } else if(!this._pageLoad) {
          $_PAGE_.addClass('loading');
        } else {
          $_PAGE_.find('.list-view-wrapper').addClass('loadingBar');
        }
      } else if($('.locations-list-view-component').length > 0) {
        // do nothing
      } else if (!this._pageLoad && !$('.listproperties').length) {
        $_PAGE_.addClass('loading');
      } else if ($_PAGE_.hasClass('rooms-rates-page')) {
        $('.listing').addClass('loadingBar');
      }
    }
  }

  end(timeout) {
    if (!(_isNumber(timeout))) {
      timeout = 333;
    }

    if (--this.count <= 0) {
      setTimeout(() => {
        if (this.count <= 0) {
          clearTimeout(timeout);
          timeout = null;
          if(!this._pageLoad) {
            $_PAGE_.removeClass('loading');
          } else if ($_PAGE_.hasClass('search-results-page') && !$_PAGE_.hasClass('search-results-page-v2')) {
            $_PAGE_.find('.list-view-wrapper').removeClass('loadingBar');
          } else if($_PAGE_.hasClass('rooms-rates-page')) {
            $('.listing').removeClass('loadingBar');
          }

          if (this._pageLoad === true) {
            EventHandler.send(EventHandler.serviceCall.end);

            this._didThings = false;
            this._pageLoad = false;
          }

          this.count = this.total;
        }
      }, timeout);
    }
  }

  getCallFromPath(path) {
    path = path.replace(/\?.*$/, '');
    path = path.replace(/\/$/, '');

    let paths = path.split('/');

    return paths[(paths.length - 1)];
  }
  listen() {
    $(document).ajaxSend((event, jqxhr, settings) => {
      ++this.total;
      if (_isNotNull(settings) && (settings !== undefined)) {
        if ((settings.url.match(/BWS/) || (settings.url.match(/WHG/) && getBrand() !== 'WR') || settings.url.match(/bin/)) &&
          $.inArray(this.getCallFromPath(settings.url), this.ignore) === -1 && !settings.url.match(/calendarRetrieve/)) {
          this.start(settings.url);
        }
      }
    });

    $(document).ajaxComplete((event, jqxhr, settings) => {
      --this.total;
      if (_isNotNull(settings) && (settings !== undefined)) {
        if ((settings.url.match(/BWS/) || (settings.url.match(/WHG/) && getBrand() !== 'WR') || settings.url.match(/bin/)) &&
          $.inArray(this.getCallFromPath(settings.url), this.ignore) === -1 && !settings.url.match(/calendarRetrieve/)) {
          this.end();
        }
      }
    });
  }
}

let thinkingInst = new Thinking();

if(isPublish === true) {
  thinkingInst.init();	// We want Thinking event listener to be the last to fire
}


export default thinkingInst;
